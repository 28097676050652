import { Modal } from "@redq/reuse-modal";
import { ResetCSS } from "common/assets/css/style";
import Heading from "common/components/Heading";
import { theme } from "common/theme/appModern";
import Seo from "components/seo";
import Navbar from "containers/AppModern/Navbar";
import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import GlobalStyle, { AppWrapper } from "../appModern.style";
import MainAboutWrapper, { ContentWrapper, CustomPara } from "./aboutUs.styles";
import companyInfo from "./data";
import Footer from "../Footer";
import Fade from "react-reveal/Fade";

const AboutUs = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Comrex Pvt Ltd:" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />

          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>

            <MainAboutWrapper>
              <ContentWrapper>
                <div style={{ textAlign: "center" }}>
                  <Fade down>
                    <Heading
                      as="h1"
                      color="white"
                      content={companyInfo.aboutUs.heading}
                    />
                  </Fade>
                </div>
                <Fade up>
                  <CustomPara>{companyInfo.aboutUs.content}</CustomPara>
                </Fade>

                <Fade up delay={100}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.contact.heading}
                  />
                  <CustomPara>
                    {companyInfo.contact.administration.name}
                  </CustomPara>
                  <CustomPara>
                    {companyInfo.contact.administration.address}
                  </CustomPara>
                  <CustomPara>
                    {companyInfo.contact.administration.email}
                  </CustomPara>
                  <CustomPara>
                    {companyInfo.contact.administration.phone}
                  </CustomPara>
                </Fade>

                <Fade up delay={150}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.representatives.heading}
                  />

                  {companyInfo.representatives.names.map((name, i) => (
                    <CustomPara key={i}>{name}</CustomPara>
                  ))}
                </Fade>

                <Fade up delay={200}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.commercialRegister.heading}
                  />

                  <CustomPara>
                    {companyInfo.commercialRegister.companyName}
                  </CustomPara>
                  <CustomPara>
                    {companyInfo.commercialRegister.registrationNumber}
                  </CustomPara>

                  {companyInfo.commercialRegister.registeredIn.map((reg, i) => (
                    <CustomPara key={i}>{reg}</CustomPara>
                  ))}

                  <CustomPara>
                    {companyInfo.commercialRegister.gstNTN}
                  </CustomPara>
                </Fade>

                <Fade up delay={250}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.disclaimer.heading}
                  />
                  {companyInfo.disclaimer.contents.map((para, i) => (
                    <CustomPara key={i}>{para}</CustomPara>
                  ))}
                </Fade>

                <Fade up delay={300}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.linksDisclaimer.heading}
                  />
                  <CustomPara>{companyInfo.linksDisclaimer.content}</CustomPara>
                </Fade>

                <Fade up delay={350}>
                  <Heading
                    as="h2"
                    color="white"
                    content={companyInfo.legal.heading}
                  />
                  <CustomPara>{companyInfo.legal.content}</CustomPara>
                </Fade>
              </ContentWrapper>
            </MainAboutWrapper>
            <Footer />
          </AppWrapper>
        </>
      </ThemeProvider>
    </div>
  );
};

export default AboutUs;
