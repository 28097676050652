import { Modal } from "@redq/reuse-modal";
import { ResetCSS } from "common/assets/css/style";
import Heading from "common/components/Heading";
import { theme } from "common/theme/appModern";
import Seo from "components/seo";
import Navbar from "containers/AppModern/Navbar";
import React from "react";
import Fade from "react-reveal/Fade";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import GlobalStyle, { AppWrapper } from "../appModern.style";
import Footer from "../Footer";
import MainCopyrightWrapper, {
  ContentWrapper,
  CustomPara,
} from "./copyright.styles";

const Copyright = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Comrex Pvt Ltd: copyright" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>

            <MainCopyrightWrapper>
              <ContentWrapper>
                <Fade down>
                  <Heading as="h1" content="Copyright" color="white" />
                </Fade>
                <Fade up>
                  <CustomPara>
                    The copyright and other rights to content, images, photos or
                    other files on this website belong to Comrex Private Limited
                    or have been licensed by other rights holders. The written
                    consent of the copyright holder must be obtained in advance
                    for the reproduction of any part.
                  </CustomPara>
                </Fade>
              </ContentWrapper>
            </MainCopyrightWrapper>
            <Footer />
          </AppWrapper>
        </>
      </ThemeProvider>
    </div>
  );
};

export default Copyright;
