import React, { useEffect } from "react";
import MainPrivacyWrapper, {
  ContentWrapper,
  CustomPara,
} from "./privacy.styles";
import { ThemeProvider } from "styled-components";
import Heading from "../../../common/components/Heading";
import { Modal } from "@redq/reuse-modal";
import { ResetCSS } from "common/assets/css/style";
import { theme } from "common/theme/appModern";
import Seo from "components/seo";
import Navbar from "containers/AppModern/Navbar";
import GlobalStyle, { AppWrapper } from "../appModern.style";
import Sticky from "react-stickynode";
import privacyStatement from "./data";
import { Link } from "gatsby";
import Footer from "../Footer";
import Fade from "react-reveal/Fade";

const PrivacyStatment = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Comrex Pvt Ltd: copyright" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>

            <MainPrivacyWrapper>
              <ContentWrapper>
                <Fade down>
                  <div style={{ textAlign: "center" }}>
                    <Heading
                      as="h1"
                      content="Privacy Statement"
                      color="white"
                    />
                  </div>
                </Fade>

                <Fade up delay={30}>
                  <Heading
                    as="h2"
                    content={privacyStatement.collectionProcessing.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.collectionProcessing.content}
                  </CustomPara>
                  <CustomPara>This is for the following reasons :</CustomPara>
                  {privacyStatement.collectionProcessing.reasons.map(
                    (reason, i) => (
                      <CustomPara key={i}>{reason}</CustomPara>
                    )
                  )}
                  <CustomPara>
                    {privacyStatement.collectionProcessing.additionalInfo}
                  </CustomPara>
                </Fade>

                <Fade up delay={60}>
                  <Heading
                    as="h2"
                    content={privacyStatement.storageDuration.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.storageDuration.content}
                  </CustomPara>
                </Fade>

                <Fade up dealy={90}>
                  <Heading
                    as="h2"
                    content={privacyStatement.contacts.heading}
                    color="white"
                  />
                  <CustomPara>{privacyStatement.contacts.content}</CustomPara>
                </Fade>

                <Fade up delay={120}>
                  <Heading
                    as="h2"
                    content={privacyStatement.cookies.heading}
                    color="white"
                  />
                  <CustomPara>{privacyStatement.cookies.content}</CustomPara>
                  <CustomPara>
                    {privacyStatement.cookies.instructions}
                  </CustomPara>
                </Fade>

                <Fade up delay={150}>
                  <Heading
                    as="h2"
                    content={privacyStatement.googleWebFonts.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.googleWebFonts.content}
                  </CustomPara>

                  <CustomPara>
                    {privacyStatement.googleWebFonts.linkInfo} <br />
                    <Link
                      className="google-link"
                      target="blank"
                      to="https://www.google.com/policies/privacy/"
                    >
                      {privacyStatement.googleWebFonts.privacyLink}
                    </Link>
                  </CustomPara>
                </Fade>

                <Fade up delay={180}>
                  <Heading
                    as="h2"
                    content={privacyStatement.embeddedContent.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.embeddedContent.content}
                  </CustomPara>
                  <CustomPara>
                    {privacyStatement.embeddedContent.moreDetails}
                  </CustomPara>
                </Fade>

                <Fade up delay={210}>
                  <Heading
                    as="h2"
                    content={privacyStatement.serverLogFiles.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.serverLogFiles.content}
                  </CustomPara>
                </Fade>

                <Fade up delay={240}>
                  <Heading
                    as="h2"
                    content={privacyStatement.googleAnalytics.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.googleAnalytics.content}
                  </CustomPara>
                  <CustomPara>
                    {privacyStatement.googleAnalytics.moreDetails}
                  </CustomPara>
                </Fade>

                <Fade up delay={270}>
                  <Heading
                    as="h2"
                    content={privacyStatement.googleMaps.heading}
                    color="white"
                  />
                  <CustomPara>{privacyStatement.googleMaps.content}</CustomPara>
                  <CustomPara>
                    {privacyStatement.googleMaps.moreDetails}
                  </CustomPara>
                </Fade>

                <Fade up dealy={300}>
                  <Heading
                    as="h2"
                    content={privacyStatement.dataProtection.heading}
                    color="white"
                  />
                  <CustomPara>
                    {privacyStatement.dataProtection.content}
                  </CustomPara>
                </Fade>

                <Fade up delay={330}>
                  <Heading
                    as="h2"
                    content={privacyStatement.changes.heading}
                    color="white"
                  />

                  <CustomPara>{privacyStatement.changes.content}</CustomPara>
                </Fade>

                <Fade up delay={360}>
                  <Heading
                    as="h2"
                    content={privacyStatement.yourRights.heading}
                    color="white"
                  />
                  <CustomPara>{privacyStatement.yourRights.content}</CustomPara>

                  <CustomPara>{privacyStatement.date}</CustomPara>
                </Fade>
              </ContentWrapper>
            </MainPrivacyWrapper>
            <Footer />
          </AppWrapper>
        </>
      </ThemeProvider>
    </div>
  );
};

export default PrivacyStatment;
