const companyInfo = {
  aboutUs: {
    heading: "About Us",
    content:
      "We are developing high quality software solutions to fit your needs and we can provide dedicated employees/team with work space as per client requirement.",
  },
  contact: {
    heading: "Contact Address",
    administration: {
      name: "Comrex Private Limited",
      address: "1st Floor, Plot, 1-H Tariq Market, Street 14 F10/2 Islamabad",
      email: "info@comrex.pk",
      phone: "+92 51 831 2025",
    },
  },
  representatives: {
    heading: "Authorized Representative(s)",
    names: ["Muhammad Fareed", "Muhammad Shams"],
  },
  commercialRegister: {
    heading: "Commercial Register Entry",
    companyName: "Registered company name: Comrex Private Limited",
    registrationNumber: "Commercial registration number : A323422",
    registeredIn: [
      "Registered in: ",
      "Pakistan Software Export Board",
      "Excise & Taxation Department Islamabad",
      "Federal Board Of Revenue Islamabad",
    ],
    gstNTN: "GST /NTN : A323422-7",
  },
  disclaimer: {
    heading: "Disclaimer",
    contents: [
      "The author assumes no liability for the correctness, accuracy, timeliness, reliability, and completeness of the information.",
      "Liability claims against the author for material or immaterial damage resulting from access to, use or non-use of the published information, misuse of the connection, or technical faults are excluded.",
      "All offers are non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to discontinue publication temporarily or permanently.",
    ],
  },
  linksDisclaimer: {
    heading: "Disclaimer for Links",
    content: `References and links to third-party websites are outside our area of responsibility. It rejected any responsibility for such websites. Access to and use of such websites is at the user's own risk.`,
  },
  legal: {
    heading: "Applicable Law and Jurisdiction",
    content: `The courts at the registered office of Comrex Private Limited in Islamabad are exclusively responsible for all possible disputes between you as a visitor and user of the Comrex websites and Comrex Private Limited, which result from the operation or visit to our websites. Pakistan law is exclusively applicable.`,
  },
};

export default companyInfo;
