import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { theme } from "common/theme/appModern";
import { ResetCSS } from "common/assets/css/style";
import Sticky from "react-stickynode";
import Navbar from "containers/AppModern/Navbar";
import Banner from "containers/AppModern/Banner";
import AppSlider from "containers/AppModern/AppSlider";
import Features from "containers/AppModern/Features";

import AboutUs from "../containers/AppModern/About-us";

import TeamPortfolio from "containers/AppModern/TeamPortfoilo";
import Testimonial from "containers/AppModern/Testimonial";
import VideoSection from "containers/AppModern/VideoSection";
import Footer from "containers/AppModern/Footer";
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/AppModern/appModern.style";
import "@redq/reuse-modal/es/index.css";

import Seo from "components/seo";
import TeamMember from "../containers/AppModern/TeamMember";
import Copright from "../containers/AppModern/copyright";
import PrivacyStatment from "../containers/AppModern/privacyStatement";
// import LoginPage from "./Login";

const AppModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Comrex Pvt Ltd:" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Features />
            <AppSlider />
            <TeamMember />
            <Testimonial />
            <VideoSection />

            {/* <DashboardFeatures /> */}
            {/* <Testimonial /> */}
            {/* <ProductSlide /> */}
            {/* <DesignedAndBuilt /> */}
            {/* <PricingPolicy /> */}
            {/* <VideoSection />
            <TeamPortfolio /> */}
            {/* <Newsletter /> */}
          </ContentWrapper>

          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default AppModern;
