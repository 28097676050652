import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const MainAboutWrapper = styled.div`
  padding-top: 100px;
  min-height: 802px;
  width: 100%;
  background-color: ${themeGet("colors.primary")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomPara = styled.p`
  font-family: "Heebo", sans-serif;
  font-weight: normal;
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  text-justify: auto;
  text-align: justify;
  line-height: 32px;
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
  padding: 1.5rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export default MainAboutWrapper;
