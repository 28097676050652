const privacyStatement = {
  collectionProcessing: {
    heading: "Collection, Processing, and Use of Personal Data",
    content: `When you access this website, information of a general nature is automatically recorded. This information is recorded in the server log file and includes the type of web browser, the operating system used, the domain name of your Internet service provider, your IP address, and the like.`,
    reasons: [
      "     -Ensuring a problem-free connection to the website",
      "     -Ensuring smooth use of our website",
      "     -Evaluation of system security and stability",
      "     -Other administrative purposes",
    ],
    additionalInfo: `Your data will not be used to draw conclusions about your person. This information is only evaluated statistically to optimize our website and its technology behind it.`,
  },
  storageDuration: {
    heading: "Storage Duration",
    content: `The data will be deleted as soon as they are no longer required for the purpose of collection. This is generally the case for the data used to provide the website when the respective session has ended.`,
  },
  contacts: {
    heading: "Contacts",
    content: `The data you send will be stored for the purpose of individual communication with you. This requires you to provide a valid email address and your name. This is used to allocate the request and then to answer it. Providing further data is optional.`,
  },
  cookies: {
    heading: "Cookies",
    content: `A so-called "session cookie" is set when certain pages are called up. This is a small text file that is automatically deleted from your computer after the end of the browser session. This file is used exclusively to enable the use of certain applications.`,
    instructions: `You can change the settings on most web browsers so that your browser does not accept new cookies or you can have received cookies deleted. Refer to your browser's help function for detailed instructions.`,
  },
  googleWebFonts: {
    heading: "Use of Script Libraries (Google Webfonts)",
    content: `In order to display our content correctly and graphically appealing across browsers, we use "Google Web Fonts" from Google LLC (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; hereinafter "Google") to display fonts on this website.`,
    linkInfo:
      "The privacy policy of the library operator Google can be found here:",
    privacyLink: "https://www.google.com/policies/privacy/",
  },
  embeddedContent: {
    heading: "Embedded Content from Other Websites",
    content: `Articles on this website may contain embedded content (e.g., videos, images, articles, etc.). Embedded content from other websites behaves exactly as if the visitor had visited the other website. These websites may collect data about you, use cookies, embed third-party tracking, and monitor your interaction with the embedded content.`,
    moreDetails:
      "These websites may collect data about you, use cookies, embed additional third party tracking services, and record your interaction with that embedded content, including your interaction with the embedded content if you have an account and are logged in to this website.",
  },
  serverLogFiles: {
    heading: "Server Log Files",
    content: `As with any connection to a web server, the server of our web hosting provider cyon in Basel, Switzerland (please check and change to your own hosting provider if necessary) logs and stores certain technical data. This data includes the IP address and the operating system of your device, the data, the access time, the type of browser and the browser request including the origin of the request (referrer). This is necessary for technical reasons in order to make our website available to you. The web hosting provider protects this data against unauthorized access with technical and organizational measures and does not pass it on to third parties. As far as we process personal data, we do this because of our interest.`,
  },
  googleAnalytics: {
    heading: "Google Analytics",
    content: `This website uses Google Analytics, a web analytics service provided by Google LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043 USA (hereinafter: "Google"). Google Analytics uses so-called "cookies", i.e. text files which are stored on your computer and which enable an analysis of your use of the website. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. Due to the activation of IP anonymization on this website, however, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide other services related to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. to compile reports on website activity and to provide other services related to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. to compile reports on website activity and to provide other services related to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.`,
    moreDetails:
      "The purpose of the data processing is to evaluate the use of the website and to compile reports on activities on the website. Based on the use of the website and the Internet, further related services are then to be provided.",
  },
  googleMaps: {
    heading: "Use of Google Maps",
    content: `On this website we use the offer of Google Maps. Google Maps is operated by Google LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (hereinafter “Google”). This enables us to show you interactive maps directly on the website and enables you to conveniently use the map function.`,
    moreDetails:
      "You can find more information about data processing by Google in the Google data protection information. There you can also change your personal data protection settings in the data protection center.",
  },
  dataProtection: {
    heading: "How We Protect Your Data",
    content: `In order to protect the security of your data during transmission, we use state-of-the-art encryption methods (SSL over HTTPS).`,
  },
  changes: {
    heading: "Changes",
    content: `We may adjust this data protection declaration at any time without prior notice. The current version published on our website applies.`,
  },
  yourRights: {
    heading: "Your Rights",
    content: `In principle, you have the right to information, correction, deletion, restriction, data transferability, revocation and objection. If you believe that the processing of your data violates data protection law or your data protection rights have otherwise been violated in any way, you can complain to the supervisory authority.`,
  },
  date: "Islamabad, Oct 1st, 2024",
};

export default privacyStatement;
